*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
