.App {
  color: white;
  height: 100vh;
  width: 100vw;
  background-image: radial-gradient(circle, #485563 0%, #29323c 100%);
}

.Logo-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 3rem);
  width: 100%;
  gap: 5%;
}

.Logo-Container img {
  height: 20vw;
  max-height: 144px;
}

.Name-Container {
  display: flex;
  justify-content: center;
  font-size: 4rem;
}

nav {
  margin: 0 1rem 0 1rem;
}
